import AnimatedNumber from 'animated-number-react'
import type { NextPage } from 'next'

import { CardWrapper, Icon } from '~/components'
import { COLORS } from '~/constants/colors'
import locale from '~/locale'
import { formatNumberToCurrencyString } from '~/utils'

import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { HomeHeader } from '~/components/Home/HomeHeader'
import { RootState } from '~/redux/store'

const Home: NextPage = () => {
    const router = useRouter()
    const myCurrency = useSelector((state: RootState) => state.currency.currencySymbol)

    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any

    const handleClickPath = (url: string) => {
        window.location.href = `${url}`
    }

    return (
        <>
            <div className="w-full h-full">
                <div className="w-full h-full flex flex-col">
                    <HomeHeader localeText={localeText}></HomeHeader>
                    <div className="flex-grow overflow-y-auto p-6">
                        <>
                            <div className="flex gap-6 mb-8">
                                <div className="grid-cols-2 grid gap-6">
                                    <CardWrapper>
                                        <div className="flex items-center mb-4 flex-wrap">
                                            <h3 className="font-semibold text-neutrals-600 text-xl mr-2">
                                                {localeText?.common?.stock_in}
                                            </h3>
                                        </div>
                                        <div className="text-xl font-semibold text-green flex items-center flex-wrap">
                                            <AnimatedNumber
                                                value={295}
                                                formatValue={(value: number) =>
                                                    formatNumberToCurrencyString(value)
                                                }></AnimatedNumber>
                                        </div>
                                    </CardWrapper>
                                    <CardWrapper>
                                        <div className="flex items-center mb-4 flex-wrap">
                                            <h3 className="font-semibold text-neutrals-600 text-xl mr-2">
                                                {localeText?.common?.stock_in}
                                            </h3>
                                        </div>
                                        <div className="text-xl font-semibold text-red flex items-center flex-wrap">
                                            <AnimatedNumber
                                                value={72}
                                                formatValue={(value: number) =>
                                                    formatNumberToCurrencyString(value)
                                                }></AnimatedNumber>
                                        </div>
                                    </CardWrapper>
                                    <CardWrapper>
                                        <div className="flex items-center mb-4 flex-wrap">
                                            <h3 className="font-semibold text-neutrals-600 text-xl mr-2">
                                                {localeText?.common?.Sales_Turnover}
                                            </h3>
                                        </div>
                                        <div className="text-xl font-semibold text-neutrals-900 flex items-center flex-wrap">
                                            <div className="flex items-center">
                                                <div className="mr-0">{myCurrency && myCurrency}</div>
                                                <AnimatedNumber
                                                    value={12900100}
                                                    formatValue={(value: number) =>
                                                        formatNumberToCurrencyString(value)
                                                    }></AnimatedNumber>
                                            </div>
                                        </div>
                                    </CardWrapper>
                                    <CardWrapper>
                                        <div className="flex items-center mb-4 flex-wrap">
                                            <h3 className="font-semibold text-neutrals-600 text-xl mr-2">
                                                {localeText?.common?.profit}
                                            </h3>
                                        </div>
                                        <div className="text-xl font-semibold text-neutrals-900 flex items-center flex-wrap">
                                            <div className="flex items-center">
                                                <div className="mr-0">{myCurrency && myCurrency}</div>
                                                <AnimatedNumber
                                                    value={4530000}
                                                    formatValue={(value: number) =>
                                                        formatNumberToCurrencyString(value)
                                                    }></AnimatedNumber>
                                            </div>
                                        </div>
                                    </CardWrapper>
                                </div>

                                <div className="grid grid-cols-2 gap-6 text-sm max-w-md w-full">
                                    <CardWrapper role="button" onClick={() => handleClickPath(`/business/product/add`)}>
                                        <div className="flex h-full flex-col justify-center items-center text-center">
                                            <Icon
                                                name="add"
                                                width={36}
                                                height={36}
                                                color={COLORS.SEMANTIC.BLUE.DEFAULT}></Icon>
                                            <span className="text-neutrals-600 mt-2">
                                                {localeText?.common?.add_product}
                                            </span>
                                        </div>
                                    </CardWrapper>
                                    <CardWrapper role="button" onClick={() => handleClickPath(`/business/stock-in`)}>
                                        <div className="flex h-full flex-col justify-center items-center text-center">
                                            <Icon
                                                name="stock-in"
                                                width={36}
                                                height={36}
                                                color={COLORS.SEMANTIC.GREEN.DEFAULT}></Icon>
                                            <span className="text-neutrals-600 mt-2">
                                                {localeText?.common?.stock_in}
                                            </span>
                                        </div>
                                    </CardWrapper>
                                    <CardWrapper role="button" onClick={() => handleClickPath(`/business/stock-out`)}>
                                        <div className="flex h-full flex-col justify-center items-center text-center">
                                            <Icon
                                                name="stock-out"
                                                width={36}
                                                height={36}
                                                color={COLORS.SEMANTIC.RED.DEFAULT}></Icon>
                                            <span className="text-neutrals-600 mt-2">
                                                {localeText?.common?.stock_out}
                                            </span>
                                        </div>
                                    </CardWrapper>
                                    <CardWrapper role="button" onClick={() => handleClickPath(`/business/stock-audit`)}>
                                        <div className="flex h-full flex-col justify-center items-center text-center">
                                            <Icon
                                                name="stock-audit"
                                                width={36}
                                                height={36}
                                                color={COLORS.SEMANTIC.AMBER.DEFAULT}></Icon>
                                            <span className="text-neutrals-600 mt-2">
                                                {localeText?.common?.stock_audit}
                                            </span>
                                        </div>
                                    </CardWrapper>
                                    <CardWrapper role="button" onClick={() => handleClickPath(`/business/staff`)}>
                                        <div className="flex h-full flex-col justify-center items-center text-center">
                                            <Icon
                                                name="staff"
                                                width={36}
                                                height={36}
                                                color={COLORS.MAIN.SECONDARY.DEFAULT}></Icon>
                                            <span className="text-neutrals-600 mt-2">{localeText?.common?.staff}</span>
                                        </div>
                                    </CardWrapper>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
